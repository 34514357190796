exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-2-js": () => import("./../../../src/pages/portfolio2.js" /* webpackChunkName: "component---src-pages-portfolio-2-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-catat-singkat-js": () => import("./../../../src/pages/privacy-policy-catat-singkat.js" /* webpackChunkName: "component---src-pages-privacy-policy-catat-singkat-js" */),
  "component---src-pages-privacy-policy-jokes-bapack-2-chrome-js": () => import("./../../../src/pages/privacy-policy-jokes-bapack2-chrome.js" /* webpackChunkName: "component---src-pages-privacy-policy-jokes-bapack-2-chrome-js" */),
  "component---src-pages-privacy-policy-jokes-bapack-2-js": () => import("./../../../src/pages/privacy-policy-jokes-bapack2.js" /* webpackChunkName: "component---src-pages-privacy-policy-jokes-bapack-2-js" */),
  "component---src-templates-posts-details-js": () => import("./../../../src/templates/posts-details.js" /* webpackChunkName: "component---src-templates-posts-details-js" */)
}

